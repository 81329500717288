<script setup lang="ts">
import { ref } from "vue";
import { NavLink, SvgIcon, KippieSidebar, KippieLink, KippieSearch } from "../";

defineExpose({ toggleOpen, close });

const emit = defineEmits(["search"]);

withDefaults(
	defineProps<{
		searchPlaceholder: string;
		links: NavLink[];
		smallLinks: NavLink[];
		ctaLinks: NavLink[];
		simple?: boolean;
	}>(),
	{
		simple: false
	}
);

const isOpen = ref<boolean>(false);

const onSearchFocus = () => {
	emit("search");
	close();
};

function toggleOpen(): void {
	isOpen.value = !isOpen.value;
}

function close(): void {
	isOpen.value = false;
}
</script>

<template>
	<nav role="navigation">
		<div class="bg-black-light">
			<div class="container py-5 flex flex-wrap max-[340px]:gap-x-2 gap-x-6 gap-y-4 items-center justify-between">
				<template v-if="simple">
					<slot name="simple" />
				</template>
				<template v-else>
					<div class="flex gap-x-4 lg:gap-x-10 items-center">
						<slot name="left" />
					</div>
					<div class="flex gap-x-2 lg:gap-x-10 items-center">
						<slot name="right" />
					</div>
				</template>
			</div>
		</div>

		<KippieSidebar v-model="isOpen" placement="left">
			<template #top>
				<KippieSearch :placeholder="searchPlaceholder" @focus="onSearchFocus" />
			</template>
			<div class="flex flex-col gap-y-10">
				<ul class="flex flex-col gap-y-4">
					<li v-for="link in links" :key="link.label" class="flex relative group">
						<SvgIcon
							name="circle"
							class="absolute top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 ease-in-out duration-200"
						/>
						<KippieLink
							:to="link.url"
							:external="link.url.includes('http')"
							:underline="false"
							class="text-3xl font-bold group-hover:pl-4 ease-in-out duration-200"
							@click="close"
						>
							{{ link.label }}
						</KippieLink>
					</li>
				</ul>
				<ul class="flex flex-col gap-y-1">
					<li v-for="link in smallLinks" :key="link.label" class="flex relative group">
						<SvgIcon
							name="circle"
							class="absolute top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 ease-in-out duration-200"
						/>
						<KippieLink
							:to="link.url"
							:external="link.url.includes('http')"
							:underline="false"
							class="text-base group-hover:pl-4 ease-in-out duration-200"
							@click="close"
						>
							{{ link.label }}
						</KippieLink>
					</li>
				</ul>
				<ul class="flex flex-col gap-y-1">
					<li v-for="link in ctaLinks" :key="link.label" class="flex relative group">
						<SvgIcon
							name="circle"
							class="absolute top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 ease-in-out duration-200"
						/>
						<KippieLink
							:to="link.url"
							:external="link.url.includes('http')"
							:underline="false"
							class="text-base font-bold group-hover:pl-4 ease-in-out duration-200"
							@click="close"
						>
							{{ link.label }}
						</KippieLink>
					</li>
				</ul>
			</div>

			<template #bottom>
				<div class="space-y-4">
					<div class="w-full h-px bg-black-light" />
					<div class="flex justify-between gap-x-4">
						<div class="flex gap-x-2">
							<slot name="socials" />
						</div>
						<div class="flex gap-x-2">
							<slot name="paymentMethods" />
						</div>
					</div>
				</div>
			</template>
			<template #right>
				<slot name="images" />
			</template>
		</KippieSidebar>
	</nav>
</template>
